import styled from "styled-components";
import BGImgage2 from "assets/images/bg2.webp";
import BGImgage3 from "assets/images/bg3.webp";

const StyledBackground = styled.div<{ imageURL: string }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  height: 100vh;
  width: 100vw;
  background-image: ${({ imageURL }) => `url(${imageURL})`};
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15); // Adjust the opacity as needed
  }
`;

const BackgroundImages = [BGImgage2, BGImgage3];

const Background = () => {
  const randomImage =
    BackgroundImages[Math.floor(Math.random() * BackgroundImages.length)];
  return <StyledBackground imageURL={randomImage} />;
};

export default Background;
