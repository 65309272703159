import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CssBaseline from "@mui/material/CssBaseline";
import ApplicationRouter from "./AppRouter";
import Background from "component/Background/Background";
import theme from "./theme";
import "swiper/css/bundle";
import "./index.css";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

const root = createRoot(rootElement);
root.render(
  <ThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>
      <CssBaseline />
      <ApplicationRouter />
      <Background />
    </StyledThemeProvider>
  </ThemeProvider>
);
