import LingoImage from "assets/images/work/lingo.webp";
import ElevuxImage from "assets/images/work/elevux.png";
import FundopolisImage from "assets/images/work/fundopolis.png";
import XurealImage from "assets/images/work/xureal.png";
import BlabsImage from "assets/images/work/blabs.png";

export const experienceData = [
  {
    key: "experience-0",
    company: "Abbott",
    roles: ["Senior Software Engineer", "Tech Lead"],
    images: [LingoImage],
    start: "2023",
    end: "Preset",
    desc: "Leading engineering teams by providing direction, estimating sprint work, and making achitectural decisions while developing mobile applications and backend systems to provide a seamless user experience.",
    link: "https://www.hellolingo.com/",
    stack: [
      "React Native",
      "TypeScript",
      "SQLite",
      "GraphQL",
      "PostgreSQL",
      "Azure",
    ],
  },
  {
    key: "experience-1",
    company: "Xureal",
    roles: ["Fullstack Developer", "Unity Developer"],
    desc: "Developed and deployed an array of features for a the Xureal platform, including web based 3D experiences with multiplayer and voice chat, a 3D content management system, and virtual product tours with AR capabilities.",
    images: [XurealImage],
    start: "2021",
    end: "2023",
    link: "https://xureal.com/",
    stack: ["React", "TypeScript", "Unity3D", "AWS"],
  },
  {
    key: "experience-2",
    company: "Elevux",
    roles: ["Frontend Developer"],
    desc: "Developed and deployed multiple applications for various clients, including Comcast, ADT, OTC, and more. Completed front-end applications ranging from multiplayer trivia, video conference tools, and 3D product tours.",
    images: [ElevuxImage],
    start: "2021",
    end: "2023",
    link: "https://www.elevux.com/",
    stack: ["React", "Aframe", "ThreeJS", "AWS"],
  },
  {
    key: "experience-6",
    company: "Brilliance Labs",
    roles: ["Software Developer"],
    desc: "Fulfilled contract work on an Admin Dashboard for a social media application. Worked on front-end styling and integrating the AWS Rekognition tool to filter user uploaded content.",
    images: [BlabsImage],
    start: "2021",
    end: "2020",
    link: "https://brilliancelabs.com/",
    stack: ["React", "Typescript", "AWS"],
  },
  {
    key: "experience-4",
    company: "Fundopolis",
    roles: ["Software Developer"],
    desc: "Developed and deployed a crowdfunding platform for small businesses and startups. Responsible for developing the front-end and back-end systems to provide a seamless user experience.",
    images: [FundopolisImage],
    start: "2019",
    end: "2020",
    link: "https://fundopolis.com/",
    stack: ["React", "NodeJS", "Gatsby", "Ghost", "AWS", "Jenkins"],
  },
  {
    key: "experience-6",
    company: "Brilliance Labs",
    roles: ["Software Developer"],
    desc: "Helped continue development on a MVP mobile application for a Restaurant App. Integrated an AR package utilized to preview restaurant menu items.",
    images: [BlabsImage],
    start: "2018",
    end: "2019",
    link: "https://brilliancelabs.com/",
    stack: ["Angular", "Ionic", "Firebase", "Wikitude AR", "iOS", "Android"],
  },
];
