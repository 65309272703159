import { lazy, Suspense, useState } from "react";

import { TopBanner } from "component/TopBanner/TopBanner";
import Loader from "component/Loader";

import Hero from "component/Hero/Hero";
import { Experience } from "component/Experience/Experience";
const GameProjects = lazy(
  () => import("component/DisplayModal/components/GameProjects")
);

const WebApps = lazy(() => import("component/WebApps/WebApps"));

const FrontPage = () => {
  const [devSelection, updateDevSelection] = useState<"web" | "game">("web");

  const showWebLayout = devSelection === "web";

  return (
    <>
      <TopBanner
        devSelection={devSelection}
        handleDevSelection={updateDevSelection}
      />
      <div>
        <div style={{ paddingTop: "80px" }}>
          <Hero showWebLayout={showWebLayout} />
          <Suspense fallback={<Loader />}>
            {showWebLayout && <Experience />}

            {showWebLayout ? <WebApps /> : <GameProjects />}
            {/* 
            <DisplayModalStyles>
              <CustomizedTimeline />
            </DisplayModalStyles> */}
          </Suspense>
        </div>
        <div
          style={{
            backgroundColor: "#1f1f1f",
            padding: "16px",
            color: "#e0e0e0",
            textAlign: "center",
          }}
        >
          2024 by John Correia
          <br />
          Created with React and TypeScript
          <br />
          Deployed via Netlify
        </div>
      </div>
    </>
  );
};

export default FrontPage;
