import styled from "styled-components";
import { styled as materialStyled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

interface ExperienceCardProps {
  experienceData: {
    key: string;
    company: string;
    roles: string[];
    images: string[];
    start: string;
    end: string;
    desc: string;
    link: string;
    stack: string[];
  };
}

const Styles = {
  Root: styled.div<{ hasLink: boolean }>`
    display: flex;
    width: 100%;
    max-width: 480px;
    min-height: 360px;
    flex-direction: column;
    color: #e0e0e0;
    padding: 32px 48px;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;

    :hover {
      background-color: rgba(94 234 212 / 10%);
      ${({ hasLink }) => (hasLink ? "cursor: pointer;" : "")}
    }

    @media screen and (max-width: 992px) {
      background-color: rgba(94 234 212 / 3%);
      min-height: 440px;
      max-width: 350px;
    }
    @media screen and (max-width: 768px) {
      min-height: 440px;
      max-width: 340px;
    }
    @media screen and (max-width: 576px) {
    }
  `,
  Header: styled.div`
    color: #e0e0e0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  ImageWrapper: styled.div`
    max-height: 72px;
    max-width: 72px;
    padding: 4px;
    border-radius: 3px;

    background-color: #ffffff;
    overflow: hidden;
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
  Flex: styled.div`
    display: flex;
  `,
  Roles: styled.div`
    color: #b0b0b0;
  `,
  Description: styled.div`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
  `,
};

const StyledChip = materialStyled(Chip)`
  font-size: 11px;
  padding:  0px;
  margin:  0 8px 8px 0px;
  height: 24px;

`;

export const ExperienceCard = ({ experienceData }: ExperienceCardProps) => {
  const { key, company, roles, images, start, end, desc, link, stack } =
    experienceData;
  return (
    <Styles.Root
      key={key}
      onClick={() => (link ? window.open(link) : null)}
      hasLink={!!link}
    >
      <Styles.Header>
        <div>
          {start + " - " + end}
          <div>
            <b>{company}</b>
          </div>
          <Styles.Roles>
            {roles.map((role, i) => (
              <div key={role + i}>{role}</div>
            ))}
          </Styles.Roles>
        </div>

        <Styles.Flex>
          {images.map((image, i) => (
            <Styles.ImageWrapper key={"experience-card-image-" + i}>
              <Styles.Image src={image} alt={company} />
            </Styles.ImageWrapper>
          ))}
        </Styles.Flex>
      </Styles.Header>

      <Styles.Description>{desc}</Styles.Description>
      <div>
        {stack.map((tech, i) => (
          <StyledChip
            key={"experience-card-tech" + i}
            label={tech}
            color={"primary"}
          />
        ))}
      </div>
    </Styles.Root>
  );
};
