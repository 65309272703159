import { CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: " center",
      }}
    >
      <CircularProgress size={120} color="primary" />
    </div>
  );
};

export default Loader;
