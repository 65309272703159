import { createTheme } from "@mui/material/styles";
// When using TypeScript 4.x and above
import type {} from "@mui/lab/themeAugmentation";

const SECONDARY_BLUE = "#94A6E8";
const GREY = "#777E91";
const FONT_WHITE = "#e0e0e0";

const theme = createTheme({
  palette: {
    primary: {
      main: FONT_WHITE,
    },
    secondary: {
      main: SECONDARY_BLUE,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "6px",
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {},
      },
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      color: FONT_WHITE,
      fontFamily: "Josefin Sans",
      fontVariant: "small-caps",
      fontSize: "36px",
      fontWeight: 600,
      letterSpacing: "0.07px",
    },
    h2: {
      color: FONT_WHITE,
      fontFamily: "Josefin Sans",
      fontVariant: "small-caps",
      fontSize: "28px",
      fontWeight: 400,
      letterSpacing: "0.07px",
    },
    h3: {
      color: FONT_WHITE,
      fontFamily: "Josefin Sans",
      fontSize: "18px",
      fontWeight: 500,
      letterSpacing: "0",
    },
    h4: {
      color: FONT_WHITE,
      fontFamily: "Josefin Sans",
      fontSize: "14px",
      fontWeight: 600,
      letterSpacing: "0.07px",
    },
    h5: {
      color: SECONDARY_BLUE,
      fontFamily: "Josefin Sans",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.07px",
    },
    h6: {
      color: FONT_WHITE,
      fontFamily: "Josefin Sans",
      fontSize: "10px",
      letterSpacing: "0.07px",
    },
    subtitle1: {
      color: GREY,
      fontFamily: "Josefin Sans",
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.04px",
    },
  },
});

export default theme;
