import { Typography } from "@mui/material";
import { experienceData } from "./data";
import styled from "styled-components";
import { ExperienceCard } from "./ExperienceCard";

const Styles = {
  Root: styled.div`
    display: flex;
    justify-content: flex;
    align-items: center;
    flex-direction: column;
    background-color: #000000ee;
    backdrop-filter: blur(2px);
    padding: 32px 128px 72px;
    overflow: visible;

    @media screen and (max-width: 992px) {
      padding: 64px 32px;
    }
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 576px) {
      padding: 64px 8px;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;

    @media screen and (max-width: 992px) {
      justify-content: center;
      gap: 20px;
    }
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 576px) {
    }
  `,
};

export const Experience = () => {
  const renderSlides = experienceData.map((experience, i) => (
    <ExperienceCard
      key={"experience-slides-" + i}
      experienceData={experience}
    />
  ));

  return (
    <Styles.Root id="experience">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <Typography variant="h1" textAlign="center">
          Experience
        </Typography>
      </div>
      <Styles.Wrapper>{renderSlides}</Styles.Wrapper>
    </Styles.Root>
  );
};
