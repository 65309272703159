import { Typography } from "@mui/material";
import styled from "styled-components";
import { styled as materialStyled } from "@mui/material/styles";
import BrushStrokeImage from "assets/images/brush-stroke.png";

const AboutStyles = {
  Root: styled.div`
    padding: 16px;
    height: 760px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s ease-in;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @media screen and (max-width: 992px) {
    }
    @media screen and (max-width: 768px) {
    }

    @media screen and (max-width: 576px) {
      height: 680px;
    }

    @media screen and (max-width: 376px) {
      height: 600px;
    }
  `,
  Title: styled.div`
    color: #e0e0e0;
    font-family: "Josefin Sans";
    font-variant: small-caps;
    font-size: 56px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    margin-bottom: 16px;
    @media screen and (max-width: 992px) {
    }
    @media screen and (max-width: 768px) {
      line-height: 30px;
      font-size: 46px;
    }
    @media screen and (max-width: 576px) {
      font-size: 32px;
      line-height: 30px;
      margin-bottom: 4px;
    }
  `,
  Subtitle: materialStyled(Typography)`
  @media screen and (max-width: 992px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
    font-size: 16px;
  }

  
  `,
  TitleContainer: styled.div`
    position: relative;
  `,
  Image: styled.img`
    position: absolute;
    z-index: -1;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: auto;
    opacity: 0.8;

    @media screen and (max-width: 992px) {
      width: 100%;
      width: 758px;
    }
    @media screen and (max-width: 768px) {
      width: 566px;
    }

    @media screen and (max-width: 576px) {
      width: 420px;
    }

    @media screen and (max-width: 376px) {
      width: 440px;
    }
  `,
};

interface AboutMeProps {
  showWebLayout: boolean;
}

const aboutMeWebText = "Senior Software Engineer & Tech Lead at Lingo";
const aboutMeGameText = "Unity Hobbyist Game Dev";

const AboutMe = ({ showWebLayout }: AboutMeProps) => {
  return (
    <AboutStyles.Root id="john">
      <AboutStyles.TitleContainer>
        <AboutStyles.Title>John P. Correia</AboutStyles.Title>
        <AboutStyles.Subtitle variant="h2" textAlign="center" color={"#e0e0e0"}>
          {showWebLayout ? aboutMeWebText : aboutMeGameText}
        </AboutStyles.Subtitle>
        <AboutStyles.Image src={BrushStrokeImage} alt="brush stroke" />
      </AboutStyles.TitleContainer>
    </AboutStyles.Root>
  );
};
export default AboutMe;
