import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Paper } from "@mui/material";
import styled from "styled-components";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import ContactPageIcon from "@mui/icons-material/ContactPage";

export const TopBarStyles = {
  Root: styled.div`
    padding: 16px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #22252edd;
    backdrop-filter: blur(1px);
    width: 100%;
    top: 0;
    z-index: 2;
  `,
  Inner: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  `,
  Right: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 140px;
    margin-right: 16px;
  `,
  Title: styled.div`
    font-size: 26px;
    font-weight: 600;
    @media only screen and (max-width: 760px) {
      font-size: 18px;
    }
  `,
  Desc: styled.div`
    margin-left: 16px;
    @media only screen and (max-width: 880px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 660px) {
      display: none;
    }
  `,
  Paper: styled(Paper)`
    border-radius: 12px;
    width: 600px;
    padding: 20px 32px;
    text-align: center;
    &.MuiPaper-root {
      border-radius: 12px;
    }
  `,
  Section: styled.div`
    margin: 32px 0;
  `,
};

interface TopBannerProps {
  devSelection: "web" | "game";
  handleDevSelection: (newAlignment: "web" | "game") => void;
}

export const TopBanner = ({
  devSelection,
  handleDevSelection,
}: TopBannerProps) => {
  return (
    <TopBarStyles.Root>
      <TopBarStyles.Inner>
        <TopBarStyles.Desc>
          {/* <StyledToggleButtonGroup
            color="primary"
            value={devSelection}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <StyledToggleButton value="web">Web Development</StyledToggleButton>
            <StyledToggleButton value="game">
              Game Development
            </StyledToggleButton>
          </StyledToggleButtonGroup> */}
        </TopBarStyles.Desc>
      </TopBarStyles.Inner>
      <TopBarStyles.Right>
        <Tooltip title="LinkedIn">
          <IconButton
            href="https://www.linkedin.com/in/john-correia/"
            target="_blank"
          >
            <LinkedInIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Email">
          <IconButton href="mailto:johnpaulcorreia@gmail.com" target="_blank">
            <MailIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Resume">
          <IconButton
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/13Saeiq2lo_0v0BWhHr-M0FE5G2A7iYiH/view?usp=sharing",
                "_blank"
              )
            }
          >
            <ContactPageIcon color="primary" />
          </IconButton>
        </Tooltip>
      </TopBarStyles.Right>
    </TopBarStyles.Root>
  );
};
